<template>
  <div class="mine" ref="minePage">
    <div class="user-info flex position_relative" @click="loginClick">
      <img v-if="!token" :src="avatar" class="avatar-img">
      <img v-if="userRole == 1" :src="zz_male" class="avatar-img">
      <img v-if="userRole == 0 || userRole == 3 || userRole == 4" :src="yunying_male" alt="" class="avatar-img">
      <img v-if="userRole == 2" :src="youke_male" alt="" class="avatar-img">
      <div class="user-info__right flex" v-if='!token'>
        <div class="user-info__login" >点击登录</div>
      </div>
      <div v-else class="user-info__info position_absolute"  >
        <div>
          {{username}} 
          <img v-if="userRole == 1" :src="zzImg" alt="" class="icon-img">
          <img v-if="userRole == 0 || userRole == 3" :src="yunyingImg" alt="" class="icon-img">
        </div>
        <div>{{phoneNo}}</div>
      </div>
      <van-icon class="position_absolute setting_btn"  name="setting-o" color="#fff" size="18" @click.stop="settingClick"/>
    </div>
    <div class="card-info">
       <div v-if="!token || userRole == 2" class="infoCard flex">
        <div class="cardInfo">
          <p>——</p>
          <div class="countValue">**</div> 
        </div>
        <div class="cardInfo">
          <p>——</p>
          <div class="countValue">**</div>  
        </div>
      </div>
      <div v-if="token" class="infoCard position_absolute flex">
        <div class="cardInfo" v-if="isShow_Mine_BuildedSite">
          <p>已建站点</p>
          <div class="countValue">{{mineInfo.stationCount}}</div>  
        </div>
        <div class="cardInfo" v-if="isShow_Mine_SignedSite">
          <p>已签约站点</p>
          <div class="countValue">{{mineInfo.signCount}}</div>
        </div>
      </div>
      <div v-if="token" class="infoCard position_absolute flex">
        <div class="cardInfo" v-if="isShow_Mine_OpenCardNum">
          <p>客户数</p>
          <div class="countValue">{{mineInfo.cardNum}}</div>
        </div>
        <div class=" cardInfo" v-if="isShow_Mine_PointBalance">
          <p>时点余额(万元)</p>
          <div class="countValue">{{mineInfo.currBalance}}</div>
        </div>
      </div>
    </div>
    <div class="content-info">
      <div class="content-info__item flex" v-if="isShow_Mine_Device || !token"  @click="myInspectClick">
        <div class="item-left flex">
          <img :src="mysiteIcon"/>
          <div class="item-name">我的考核</div>
        </div>
        <van-icon name="arrow" size="0.5rem" color="#90979c"/>
      </div>
      <div class="content-info__item flex" v-if="isShow_Mine_mySite || !token"  @click="mysiteClick">
        <div class="item-left flex">
          <img :src="mysiteIcon"/>
          <div class="item-name">我的站点</div>
        </div>
        <van-icon name="arrow" size="0.5rem" color="#90979c"/>
      </div>
      <div v-if="isShow_Mine_myOrg || !token" class="content-info__item flex"  @click="orgClick">
        <div class="item-left flex">
          <img :src="orgIcon"/>
          <div class="item-name">我的组织</div>
        </div>
        <van-icon name="arrow" size="0.5rem" color="#90979c"/>
      </div>
      <div v-if="isShow_Mine_Device || !token" class="content-info__item flex"  @click="deviceClick">
        <div class="item-left flex">
          <img :src="deviceIcon"/>
          <div class="item-name">我的设备</div>
        </div>
        <van-icon name="arrow" size="0.5rem" color="#90979c"/>
      </div>
      <div v-if="isShow_Mine_myAgreement" class="content-info__item flex"  @click="agreementClick">
        <div class="item-left flex">
          <img :src="xieyiIcon"/>
          <div class="item-name">我的协议</div>
        </div>
        <van-icon name="arrow" size="0.5rem" color="#90979c"/>
      </div>
      <div v-if="isShow_Mine_bankCard" class="content-info__item flex"  @click="bankCardClick">
        <div class="item-left flex">
          <img :src="bankIcon"/>
          <div class="item-name">我的银行卡</div>
        </div>
        <van-icon name="arrow" size="0.5rem" color="#90979c"/>
      </div>
      <div v-if="isShow_Mine_Safeguard" class="content-info__item flex"  @click="safeguardClick">
        <div class="item-left flex">
          <img :src="bankIcon"/>
          <div class="item-name">我的保障</div>
        </div>
        <van-icon name="arrow" size="0.5rem" color="#90979c"/>
      </div>
    </div>
    <div class="setting-info" >
      <div class="content-info__item flex " @click="qaClick">
        <div class="item-left flex align_items_center">
          <img :src="qaIcon" />
          <div class="item-name">常见问题</div>
        </div>
        <van-icon name="arrow" size="0.5rem" color="#90979c"/>
      </div>
      <div class="content-info__item flex" @click="feedbackClick">
        <div class="item-left flex align_items_center">
          <img :src="feedbackIcon"  />
          <div class="item-name">意见反馈</div>
        </div>
        <van-icon name="arrow" size="0.5rem" color="#90979c"/>
      </div>
    </div>
    <router-view />



  <p class="serviceTel" :class="{'serviceTelfixed':clientHeight > 568}"><a href="tel:400-032-5263">客服电话：400-032-5263</a></p>

    <van-tabbar route active-color="#3F7C53" inactive-color="#B5BBC9">
      <van-tabbar-item replace to="/main/homePage" >
        首页
        <template #icon="props">
          <img :src="props.active ? homeIcon.active : homeIcon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/News/newsHome">
        村口头条
        <template #icon="props">
          <img :src="props.active ? newsIcon.active : newsIcon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/main/mine" >
        我的
        <template #icon="props">
          <img :src="props.active ? mineIcon.active : mineIcon.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Icon } from 'vant'
import common from '@/utils/common'
import {getMineInfo} from '@/utils/api'
import mysiteIcon from '../../assets/img/mine/site.png'
import orgIcon from '../../assets/img/mine/org.png'
import deviceIcon from '../../assets/img/mine/myDevice.png'
import xieyiIcon from '../../assets/img/mine/xieyi.png'
import bankIcon from '../../assets/img/mine/bank.png'
import feedbackIcon from '../../assets/img/mine/feedback.png'
import qaIcon from '../../assets/img/mine/qa.png'
import {mapGetters} from 'vuex'
import {Mine_Page} from "../../utils/constantNum";
import {getEquipmentDeposit} from "../../utils/api";
import {uwStatisticAction} from "../../utils/util";
export default {
  components:{
    [Icon.name]: Icon
  },
  data() {
    return {
      avatar: require('../../assets/img/mine/avator.png'),
      setting: require('../../assets/img/mine/setting.png'),
      zzImg: require('@/assets/img/mine/zz.png'),
      yunyingImg:require('@/assets/img/mine/yunying.png'),
      username: '' ,
      phoneNo: '',
      userRole: '',
      mineInfo:{},
      homeIcon:{
        active: require('@/assets/img/tabBar/home_1.png'),
        inactive: require('@/assets/img/tabBar/home_0.png'),
      },
      newsIcon:{
        active: require('@/assets/img/tabBar/news_1.png'),
        inactive: require('@/assets/img/tabBar/news_0.png'),
      },
      mineIcon:{
        active: require('@/assets/img/tabBar/mine_1.png'),
        inactive: require('@/assets/img/tabBar/mine_0.png'),
      },
      mysiteIcon,
      orgIcon,
      deviceIcon,
      xieyiIcon,
      bankIcon,
      feedbackIcon,
      qaIcon,
      token:'',
      youke_female: require('@/assets/img/mine/youke_female.png'),
      youke_male: require('@/assets/img/mine/youke_male.png'),
      yunying_female: require('@/assets/img/mine/yunying_female.png'),
      yunying_male: require('@/assets/img/mine/yunying_male.png'),
      zz_female: require('@/assets/img/mine/zz_female.png'),
      zz_male: require('@/assets/img/mine/zz_male.png'),
        clientHeight:667
    }
  },
  computed:{
    ...mapGetters([
            'isShow_Mine_bankCard',
            'isShow_Mine_myAgreement',
            'isShow_Mine_myOrg',
            'isShow_Mine_mySite',
            'isShow_Mine_Safeguard',
            'isShow_Mine_BuildedSite',
            'isShow_Mine_SignedSite',
            'isShow_Mine_OpenCardNum',
            'isShow_Mine_PointBalance',
            'isShow_Mine_Device'
    ])
  },
  methods: {
    toGetMineInfo(){
      getMineInfo({
        'uniqueNo':common.getItem('uniqueNo'),
      }).then(res=>{
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            this.mineInfo = res.data;
            this.username = this.mineInfo.name
            this.phoneNo = this.mineInfo.phoneNo
          }
        }
      }).catch(()=>{
      })
    },
    qaClick() {
      if (common.getItem('wtToken')){
        this.$router.push({ name: 'qa' })
      }else {
        this.$router.push({ path:'/main/login', query:{ back001:'1' }})
      }
    },
    feedbackClick(){
      // if (common.getItem('wtToken')){
      //   this.$router.push({ name: 'feedbackInput' })
      // }else {
      //   this.$router.push({ path:'/main/login', query:{ back001:'1' }})
      // }
      this.$router.push('/mine/mySafeguard')
    },
    settingClick() {

      if (common.getItem('wtToken')){
        this.$router.push({ name: 'setting' })
      }else {
        this.$router.push({
          path:'/main/login',
          query:{
            back001:'1'
          }
        })
      }
    },
    myTalentClick(){
      if (common.getItem('wtToken')){
        if (this.userRole == 1){
          this.$router.push({name:'siteBriefInfo',query:{stationNo:common.getItem('stationNo')}})
        } else {
          this.$router.push({ name: 'mysite', query:{role: this.userRole,pageType:Mine_Page}})
        }
      }else {
        this.$router.push({
          path:'/main/login',
          query:{
            back001:'1'
          }
        })
      }
    },
    myInspectClick(){
      if (common.getItem('wtToken')){
          this.$router.push({name:'inspectList',query:{stationNo:common.getItem('stationNo')}})
      }else {
        this.$router.push({
          path:'/main/login',
          query:{
            back001:'1'
          }
        })
      }

    },
    mysiteClick(){
      if (common.getItem('wtToken')){
        if (this.userRole == 1){
          this.$router.push({name:'siteBriefInfo',query:{stationNo:common.getItem('stationNo')}})
        } else {
          this.$router.push({ name: 'mysite', query:{role: this.userRole,pageType:Mine_Page}})
        }
      }else {
        this.$router.push({
          path:'/main/login',
          query:{
            back001:'1'
          }
        })
      }

    },
    orgClick(){
      if (common.getItem('wtToken')){
        if(common.getItem('userRole') == 0 || common.getItem('userRole') == 3){ // 运营
          this.$router.push({ name:'organizationyy' })
        }
        if(common.getItem('userRole') == 1){ // 站长
          this.$router.push({ name:'organization' })
        }
      }else {
        this.$router.push({
          path:'/main/login',
          query:{
            back001:'1'
          }
        })
      }
    },
    deviceClick(){
      if (common.getItem('wtToken')){
        this.$router.push({ name:'deviceCash' })
      }else {
        this.$router.push({
          path:'/main/login',
          query:{
            back001:'1'
          }
        })
      }
    },
    agreementClick(){
      if (common.getItem('wtToken')){
        this.$router.push({ name:'agreement' })
      }else {
        this.$router.push({
          path:'/main/login',
          query:{
            back001:'1'
          }
        })
      }

    },
    bankCardClick(){
      if (common.getItem('wtToken')){
        this.$router.push({ name:'card' })
      }else {
        this.$router.push({
          path:'/main/login',
          query:{
            back001:'1'
          }
        })
      }
    },
    safeguardClick(){
      if (common.getItem('wtToken')){
        this.$router.push({ name:'mySafeguard' })
      }else {
        this.$router.push({
          path:'/main/login',
          query:{
            back001:'1'
          }
        })
      }
    },
    loginClick() {
      if(!common.getItem('userName')){
        this.$store.dispatch('keepAliveState/addKeepAliveAction', 'login')
        this.$router.push({name: 'login', query:{redirect: 'mine'}})
      }
    }
  },
  mounted() {
    this.clientHeight = document.body.clientHeight
    console.log('clientHeight：',document.body.clientHeight)
    this.token = common.getItem('wtToken')
    this.userRole =common.getItem('userRole')
    console.log("userRole:",this.userRole)
    if (common.getItem('uniqueNo') != undefined){
      this.toGetMineInfo();
    }
    uwStatisticAction('/mine','我的')

  }
}
</script>

<style lang="less" scoped>
.mine{
  background: #f5f5f5;
  height: 100%;
  font-size: 0.3rem;
  .flex{
    display: flex;
  }
  .user-info{
    background: url("../../assets/img/mine/bg.png");
    background-size: 100% 100%;
    height: 156px;
    z-index: 10;
    .avatar-img{
      width: 60px;
      height: 60px;
      margin-left: 14px;
      margin-top: 60px;
      border-radius: 50%;
    }
    .user-info__right{
      margin-left: 12px;
      margin-top: 74px;
      justify-content: space-between;
    }
    .user-info__login{
      width: 92px;
      height: 32px;
      line-height: 32px;
      border-radius: 16px;
      text-align: center;
      font-size:15px;
      color: #3F7C53;
      background: #fff;
    }
    .user-info__info{
      margin-left: 13px;
      font-size:18px;
      font-family:PingFangSC-Regular,PingFang SC;
      color:#FFFFFF;
      line-height:25px;
      top: 65px;
      left: 70px;
      div{
        &:nth-child(1){
          font-size: 24px;
          font-weight:400;
        }
        &:nth-child(2){
          margin-top: 4px;
          height:21px;
          font-size:15px;
          line-height:21px;
        }
        // i{
        //   width: 42px;
        //   height: 20px;
        //   display: inline-block;
        //   vertical-align: bottom;
        //   background: url("../../assets/img/mine/zz.png");
        //   background-size: 100% 100% ;
        //   margin-left:6px ;

        // }
      }

    }
    .icon-img{
      width:40px;
    }
    .user-info__role{
      background: #757575;
      color: #efefef;
      width: 1rem;
      height: 0.5rem;
      line-height: 0.5rem;
      text-align: center;
      border-radius: 0.1rem;
    }
    
  }
  .card-info{
    background: #fff;
    height: 120px;
    margin-top: -20px;
    position: relative;
    .infoCard{
      width: 100%;
      padding: 45px 0;
      .cardInfo{
        text-align: center;
        flex: 1;
        position: relative;
        &:after{
          content: '';
          width: 1px;
          height: 50px;
          background: #EAEAEA;
          right: 0;
          top: 0;
          position: absolute;
        }
        &:nth-last-child(1){
          &:after{
            content: '';
            width: 0;
          }
        }
        .countValue{
          height:20px;
          text-align: center;
          font-size:32px;
          line-height: 38px;
          padding-left:-2px;
          font-weight: 500;
          color: #272829;
        }
        p{
          text-align: center;
          height:20px;
          line-height: 17px;
          font-size:12px;
          letter-spacing: 2px;
          color: #A8A5A8;
        }
      }
      .leftBorder{
        border-left: 0.5px solid #EEEEEE;
      }

    }
  }
  .setting-info{
    margin-top: 0.2rem !important;
  }
  .content-info, .setting-info{
    margin-top: 0.2rem;
    background: #fff;
    border-radius: 0.1rem;
    .content-info__item{
      height: 48px;
      align-items: center;
      margin: 0 0.2rem;
      justify-content: space-between;
      border-bottom: 0.5px solid #EEEEEE;
      .item-name{
        margin-left: 0.3rem;
        font-size: 0.32rem;
        line-height: 48px;
        height: 48px;
      }
      .item-left{
        align-items: center;
        img{
          width:18px;
          height:18px;
        }
      }
      &:nth-last-child(1){
        border-bottom: 0;
      }
    }
  }
  .zz_infoItem{
    margin: 0.2rem 0 0 0;
    background-color: #3F7C53;
    border-radius: 0.1rem;
    height: 80px;
    .zz_infoItem_top{
      width: 100%;
      height: 50px;
      text-align: center;
      display: flex;
      white-space: pre;
    }
    .zz_infoItem_bottom{
      width: 100%;
      height: 30px;
      text-align: center;
      display: flex;
      white-space: pre;
    }
  }

  .sc_infoItem{

    .sc_infoItem_top{
      width: 100%;
      height: 70px;
      /*display: flex;*/
      white-space: pre;
    }
  }

    .serviceTel{
        margin-top: 20px;
        text-align: center;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #CCCCCC;
        padding-bottom: 50px;
        a:link{
            color: #CCCCCC!important;
        }
    }
  .serviceTelfixed{
    position: absolute;
    bottom: 70px;
    left: 0;
    width: 100%;
    text-align: center;

    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #CCCCCC;
    padding: 0;
    a:link{
      color: #CCCCCC!important;
    }
  }
  .setting_btn{
    right: 15px;
    top: 16px;
  }
}
</style>
<style >
 .mine .van-icon-arrow{
    font-size: 20px !important;
    color: #CCCCCC !important;
   font-weight: 200;
  }
</style>

